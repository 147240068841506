.issues-header {
    font-size: 40px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.issues-body-div {
    text-align: center;
    width: 100%;
}

@media (max-width:1380px){
    .issues-header{
        font-size: 35px;
    }
}

@media (max-width:768px){
    .issues-header{
        font-size: 25px;
    }
}