.raw_logo {
  height: 305;
  width: 440;
}
.splash{
  size: cover;
  position: center;
}
.text{
  font-size:25px ;
  font-weight: bold;
  position: center;
}
